
$main-background-color: #fff;
$leaf-empty-color: #eee;
$leaf-fill-color: #ffbc2e;
$leaf-fill-color2: #2196f3;

$leaf-width: 62px;
$leaf-height: 35px;

$leaf-size: 1;
$leaf-time: 1.5s;

.c-leaf {
    position: relative;
    
    overflow: hidden;
    width: $leaf-width;
    height: $leaf-height;
    margin: 0 auto;
    
    //background: $leaf-empty-color;
    
    transform: scale($leaf-size);
}

.c-leaf__icon {
    position: absolute;
    z-index: 1;
    
    width: $leaf-width;
    height: $leaf-height;
        
    //fill: $main-background-color;
}
.c-leaf__icon-group {
    fill: transparent;
}

.c-leaf__fill {
    width: $leaf-width*0.21;
    height: $leaf-height;
    
    background: $leaf-fill-color2;
            
    animation: fillUp $leaf-time ease-in-out infinite;
}
.c-leaf_fills {
	padding: 1px 0;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}
.c-leaf__fill2 {
    width: $leaf-width*0.79;
    height: $leaf-height;
    
    background: $leaf-fill-color;
            
    animation: fillUp $leaf-time ease-in-out infinite;
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
@keyframes fillUp {
    0% {
        transform: translate(0, $leaf-height);
    }
    100% {
        transform: translate(0, -$leaf-height);
    }
}




.x-reset-container {
    display: flex;
    align-items: center;
    padding: 50px;
    animation: bounce 2s infinite;
}